<template>
  <div>
    <el-form :model="form"
             class="form_warp"
             ref="formRef"
             label-width="110px">
      <el-row :gutter="10">
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="12">
          <el-form-item label="科目:"
                        prop="subject_id">
            <el-input style="width:100%"
                      v-model="form.subject_name"
                      readonly />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="12">
          <el-form-item label="试卷来源:"
                        prop="paperSource">
            <el-input style="width:100%"
                      v-model="form.source_name"
                      readonly />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="12">
          <el-form-item label="题目年份:"
                        prop="paperYear">
            <el-input style="width:100%"
                      v-model="form.year"
                      readonly />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <topic ref="topicRef"
           style="margin-right:150px"
           :item="item"
           :index="index"
           v-for="(item,index) in info"
           :key="index" />

    <scrollTo ref="scrollToRef"
              v-if="info.length"
              :list="childredList" />
  </div>
</template>

<script>
import topic from './topic.vue'
import scrollTo from '@/components/scrollTo'

export default {
  name: 'examinationEntryProofread',
  components: {
    topic, scrollTo
  },
  data () {
    return {
      form: {
        subject_name: '',
        source_name: '',
        year: '',
      },
      info: [],
      childredList: 0,
      paperChecked: 10,
    }
  },
  mounted () {
    document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
    this.getForm()
  },
  methods: {
    getForm () {
      let queryForm = JSON.parse(this.$route.query.form)
      this.$http({
        url: '/api/v1/school/source_detail',
        method: 'get',
        params: {
          source_id: queryForm.source_id
        }
      }).then(res => {
        this.form = res.data
        this.form.year = queryForm.year

        this.getInfo()
      })
    },
    getInfo () {
      this.$refs.formRef.validate(val => {
        let queryForm = JSON.parse(this.$route.query.form)

        this.$http({
          url: '/api/v1/question/proofread',
          method: 'post',
          data: {
            paperSource: queryForm.source_id,
            paperYear: queryForm.year
          }
        }).then(res => {
          this.info = res.data.list
          this.paperChecked = res.data.paperChecked
          this.$nextTick(() => {
            var oFixed = document.getElementsByClassName('yuan')
            this.childredList = oFixed.length
          })
        })
      })

    },
  }
}
</script>

<style lang="scss" scoped>
.form_warp {
  padding-top: 10px;
  background: white;
}
</style>